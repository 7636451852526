import React, { useEffect, useState } from 'react';
import './App.css';
import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';
import {
  Program, Provider, web3,
} from '@project-serum/anchor';
import twitterLogo from './assets/twitter-logo.svg';
import idl from './idl.json';
import kp from './keypair.json';

// SystemProgram is a reference to the Solana runtime!
const { SystemProgram, ...otherProps } = web3;

// Create a keypair for the account that will hold the GIF data.
const arr = Object.values(kp.keypair.secretKey);
const secret = new Uint8Array(arr);
const baseAccount = web3.Keypair.fromSecretKey(secret);

// Get our program's id form the IDL file.
const programID = new PublicKey(idl.metadata.address);

// Set our network to devent.
const network = clusterApiUrl('devnet');

// Control's how we want to acknowledge when a trasnaction is "done".
const opts = {
  preflightCommitment: 'processed',
};

// Constants
const TWITTER_HANDLE = 'thomas_duchamp';
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;

// const TEST_GIFS = [
//   'https://media1.giphy.com/media/yoJC2JaiEMoxIhQhY4/giphy.gif?cid=91e0474es7kksqu26dkogg6s0hjww3o2eu1icidutnnr2yj2&rid=giphy.gif&ct=g',
//   'https://media1.giphy.com/media/koPSBhdvbCD4c/giphy.gif?cid=91e0474es7kksqu26dkogg6s0hjww3o2eu1icidutnnr2yj2&rid=giphy.gif&ct=g',
//   'https://media2.giphy.com/media/1iTHPSWUwhzOCtA4/giphy-downsized.gif?cid=91e0474es7kksqu26dkogg6s0hjww3o2eu1icidutnnr2yj2&rid=giphy-downsized.gif&ct=g',
//   'https://media2.giphy.com/media/xmxGoENM1V81q/giphy.gif?cid=91e0474es7kksqu26dkogg6s0hjww3o2eu1icidutnnr2yj2&rid=giphy.gif&ct=g',
//   'https://media2.giphy.com/media/d2Z4rTi11c9LRita/giphy.gif?cid=91e0474es7kksqu26dkogg6s0hjww3o2eu1icidutnnr2yj2&rid=giphy.gif&ct=g',
//   'https://media1.giphy.com/media/RKJFT4mi3o6xW/giphy.gif?cid=91e0474es7kksqu26dkogg6s0hjww3o2eu1icidutnnr2yj2&rid=giphy.gif&ct=g',
// ];

const App = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [gifList, setGifList] = useState([]);

  /*
   * This function holds the logic for deciding if a Phantom Wallet is
   * connected or not
   */
  const checkIfWalletIsConnected = async () => {
    try {
      const { solana } = window;

      if (solana) {
        if (solana.isPhantom) {
          console.log('Phantom wallet found!');
          /*
         * The solana object gives us a function that will allow us to connect
         * directly with the user's wallet!
         */
          const response = await solana.connect({ onlyIfTrusted: true });
          console.log(
            'Connected with Public Key:',
            response.publicKey.toString(),
          );
          /*
        * Set the user's publicKey in state to be used later!
        */
          setWalletAddress(response.publicKey.toString());
        }
      } else {
        alert('Solana object not found! Get a Phantom Wallet 👻');
      }
    } catch (error) {
      console.log.error(error);
    }
  };

  /*
   * Let's define this method so our code doesn't break.
   * We will write the logic for this next!
   */
  const connectWallet = async () => {
    const { solana } = window;

    if (solana) {
      const response = await solana.connect();
      console.log('Connected with Public Key:', response.publicKey.toString());
      setWalletAddress(response.publicKey.toString());
    }
  };

  const onInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new Provider(
      connection, window.solana, opts.preflightCommitment,
    );
    return provider;
  };

  const getGifList = async () => {
    try {
      const provider = getProvider();
      const program = new Program(idl, programID, provider);
      const account = await program.account.baseAccount.fetch(baseAccount.publicKey);

      console.log('Got the account', account);
      setGifList(account.gifList);
    } catch (error) {
      console.log('Error in getGifs: ', error);
      setGifList(null);
    }
  };

  const sendGif = async () => {
    if (inputValue.length === 0) {
      console.log('No gif link given!');
      return;
    }
    console.log('Gif link:', inputValue);
    try {
      const provider = getProvider();
      const program = new Program(idl, programID, provider);

      await program.rpc.addGif(inputValue, {
        accounts: {
          baseAccount: baseAccount.publicKey,
          user: provider.wallet.publicKey,
        },
      });
      console.log('GIF sucesfully sent to program', inputValue);

      await getGifList();
    } catch (error) {
      console.log('Error sending GIF:', error);
    }
  };

  const upvoteGif = async (event) => {
    console.log(event);
    try {
      const provider = getProvider();
      const program = new Program(idl, programID, provider);
      await program.rpc.upvoteGif(event.target.value, {
        accounts: {
          baseAccount: baseAccount.publicKey,
          user: provider.wallet.publicKey,
        },
      });
    } catch (error) {
      console.log('Error upvoting GIF:', error);
    }
  };

  const createGifAccount = async () => {
    try {
      const provider = getProvider();
      const program = new Program(idl, programID, provider);
      console.log('ping');
      await program.rpc.startStuffOff({
        accounts: {
          baseAccount: baseAccount.publicKey,
          user: provider.wallet.publicKey,
          systemProgram: SystemProgram.programId,
        },
        signers: [baseAccount],
      });
      console.log('Created a new BaseAccount w/ address:', baseAccount.publicKey.toString());
      await getGifList();
    } catch (error) {
      console.log('Error creating BaseAccount account:', error);
    }
  };

  /*
  * We want to render this UI when the user hasn't connected
  * their wallet to our app yet.
  */
  const renderNotConnectedContainer = () => (
    <button
      type="button"
      className="cta-button connect-wallet-button"
      onClick={connectWallet}
    >
      Connect to Wallet
    </button>
  );

  const renderConnectedContainer = () => {
    // If we hit this, it means the program account hasn't be initialized.
    if (gifList === null) {
      return (
        <div className="connected-container">
          <button className="cta-button submit-gif-button" onClick={createGifAccount} type="button">
            Do One-Time Initialization For GIF Program Account
          </button>
        </div>
      );
    }
    // Otherwise, we're good! Account exists. User can submit GIFs.

    return (
      <div className="connected-container">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            sendGif();
          }}
        >
          <input
            type="text"
            placeholder="Enter gif link!"
            value={inputValue}
            onChange={onInputChange}
          />
          <button type="submit" className="cta-button submit-gif-button">
            Submit
          </button>
        </form>
        <div className="gif-grid">
          {/* We use index as the key instead, also, the src is now item.gifLink */}
          {gifList.map((item) => (
            <div className="gif-item" key={`index_${item.id}`} id={item.id}>
              <img src={item.gifLink} alt="gif" />
              <div className="gradient-text">
                This gif was submited by user
                {item.userAddress.toString()}
              </div>
              <div className="gradient-text nb-votes">
                Nb votes:
                {item.nbVotes.toString()}
              </div>
              <button type="button" className="cta-button submit-gif-button" onClick={upvoteGif} value={item.gifLink}>
                Vote
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  /*
   * When our component first mounts, let's check to see if we have a connected
   * Phantom Wallet
   */
  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener('load', onLoad);
    return () => window.removeEventListener('load', onLoad);
  }, []);

  useEffect(() => {
    if (walletAddress) {
      console.log('Fetching GIF list...');
      getGifList();
    }
  }, [walletAddress]);

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header">🥊 Welcome to Bagarre GIF Land 🥊</p>
          <p className="sub-text">
            View your bagarre GIF collection in the metaverse ✨
          </p>
          {!walletAddress && renderNotConnectedContainer()}
          {walletAddress && renderConnectedContainer()}
        </div>
        <div className="footer-container">
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >
            {`built by @${TWITTER_HANDLE}`}
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
